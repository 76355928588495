/* stylelint-disable color-function-notation */
/* stylelint-disable alpha-value-notation */
/* stylelint-disable at-rule-no-deprecated */
/* stylelint-disable */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* stylelint-enable */

@layer base {
    :root {
        --mat-autocomplete-background-color: #fff;
        --mat-menu-container-shape: 4px;
        --mdc-icon-button-state-layer-size: 32px;
        --mat-datepicker-calendar-date-selected-state-text-color: white;
        --mat-datepicker-calendar-date-selected-state-background-color: #014785;
        --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
        --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
        --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
        --mat-datepicker-calendar-date-in-range-state-background-color: #7499b7;
        --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
        --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
        --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
        --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
        --mat-datepicker-calendar-body-label-text-color: white;
        --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
        --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
        --mat-datepicker-calendar-header-text-color: #3a3a3a;
        --mat-datepicker-calendar-date-today-outline-color: #ccdae7;
        --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
        --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
        --mat-datepicker-calendar-date-outline-color: transparent;
        --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
        --mat-datepicker-calendar-date-preview-state-outline-color: #ccdae7;
        --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
        --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
        --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
        --mat-datepicker-calendar-container-background-color: white;
        --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
        --mat-full-pseudo-checkbox-selected-icon-color: #ff4081;
        --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
        --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
        --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
        --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
        --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
        --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ff4081;
        --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
        --mat-ripple-color: rgba(0, 0, 0, 0.1);
        --mat-option-selected-state-label-text-color: #3f51b5;
        --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
        --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
        --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
        --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
        --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);

        /* unset bootstrap vars for this component. Remove me after bootstrap has been removed */
        --bs-tertiary-bg: #fff;
    }

    /* BOOTSTRAP: refactor page */
    body {
        @apply font-sans;
        @apply text-base;
        @apply text-body;
        @apply dark:text-white;
        @apply bg-white;
        @apply dark:bg-dark-background;
        @apply min-h-screen;
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5 {
        @apply mb-4;
        @apply font-ci;
        @apply text-primary;
        @apply dark:text-dark-primary;
        @apply font-medium;
        @apply leading-[1.2];
    }

    h1,
    .h1 {
        font-size: calc(1.7719rem + 0.9707vw);

        @apply xl:text-4xl;
    }

    h2,
    .h2 {
        font-size: calc(1.4587rem + 0.3883vw);

        @apply xl:text-3xl;
    }

    h3,
    .h3 {
        font-size: calc(1.3543rem + 0.1941vw);

        @apply xl:text-2xl;
    }

    h4,
    .h4 {
        @apply text-xl;
    }

    h5,
    .h5 {
        @apply text-lg;
    }

    /* bootstrap legacy: remove together with "float-left" on all legend tags */
    legend + * {
        @apply clear-left;
    }

    a {
        @apply underline;
    }

    a:hover,
    a:active,
    a:focus {
        @apply text-primary-light;
    }

    a:focus-visible {
        @apply outline-offset-[5px] outline-primary;
    }

    button,
    div {
        @apply outline-primary;
    }

    p,
    ul {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    input {
        @apply dark:!bg-dark-contrast;
        @apply dark:border-none;
    }

    ol ol,
    ul ul,
    ol ul,
    ul ol {
        @apply mb-0;
    }

    th {
        @apply text-start;
        @apply font-medium;
    }

    fieldset {
        min-inline-size: unset;
    }

    .bg-default {
        @apply bg-white dark:bg-dark-background;
    }

    .bg-alt {
        @apply bg-gray-400;
        @apply dark:bg-dark-contrast;
    }

    .bg-contrast {
        @apply bg-gray-300;
        @apply dark:bg-gray-700;
    }

    .container {
        @apply print:!max-w-[unset];
    }
}

@layer components {
    /*
    Form Controls (used by libs/form, lib/service-tools, etc.)
    Input & Selects
    */
    .form-control,
    .form-select {
        @apply appearance-none;
        @apply bg-white;
        @apply block;
        @apply border-2;
        @apply border-gray-300;
        @apply px-4;
        @apply py-[0.825rem];
        @apply rounded-md;
        @apply text-[1rem];
        @apply text-gray-100;
        @apply dark:text-white;
        @apply transition-[border-color];
        @apply transition-[box-shadow];
        @apply w-full;
    }

    .form-select {
        @apply bg-no-repeat;
        @apply bg-[right_1rem_center];
        @apply pl-4;
        @apply pr-12;

        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22px' height='22px' viewBox='0 0 128 128'>%3cpath d='M64 95.34a9 9 0 0 1-6.36-2.63L4.47 39.54a5 5 0 0 1 7.07-7.08L64 84.93l52.47-52.47a5 5 0 0 1 7.07 7.08L70.36 92.71A9 9 0 0 1 64 95.34z' fill='rgb(1, 71, 133)' />%3c/svg>");
    }

    .form-control::placeholder,
    .form-select::placeholder {
        @apply text-gray-100/75;
        @apply dark:text-white/50;
    }

    .form-select:disabled,
    .form-select.disabled {
        @apply text-gray-200;

        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22px' height='22px' viewBox='0 0 128 128'>%3cpath d='M64 95.34a9 9 0 0 1-6.36-2.63L4.47 39.54a5 5 0 0 1 7.07-7.08L64 84.93l52.47-52.47a5 5 0 0 1 7.07 7.08L70.36 92.71A9 9 0 0 1 64 95.34z' fill='rgb(229 231 235)' />%3c/svg>");
    }

    .form-control:focus,
    .form-select:focus {
        @apply border-gray-200;
        @apply outline-none;
        @apply shadow-none;
    }

    .form-control.is-valid:not(:disabled),
    .form-select.is-valid:not(:disabled) {
        @apply border-success;
    }

    big-ui-date-range.is-invalid:not(:disabled),
    .form-control.is-invalid:not(:disabled),
    .form-select.is-invalid:not(:disabled) {
        @apply border-danger;
    }

    /* Radios */
    .form-check {
        @apply block;
        @apply mb-[.125rem];
        @apply min-h-6;
        @apply pl-6;
    }

    .form-check-input {
        @apply align-top;
        @apply appearance-none;
        @apply bg-white;
        @apply border-gray-300;
        @apply border-solid;
        @apply border-2;
        @apply h-4;
        @apply mt-1;
        @apply w-4;
        @apply bg-no-repeat;
        @apply bg-center;
        @apply bg-contain;
    }

    .form-check .form-check-input {
        @apply float-left;
        @apply -ml-6;
    }

    .form-check-input[type='radio'] {
        @apply rounded-[50%];
    }

    .form-check-input[type='radio']:checked {
        @apply bg-primary;
        @apply border-primary;

        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='rgb(255, 255, 255)'/%3e%3c/svg%3e");
    }

    .form-check-input[type='radio'].is-valid {
        @apply border-success;
    }

    .form-check-input[type='radio'].is-invalid {
        @apply border-danger;
    }

    /* Hide Edge's Reveal icon */

    /* TODO: kann evtl. raus wegen chromium */
    .form-control[type='password']::-ms-clear,
    .form-control[type='password']::-ms-reveal {
        display: none;
    }

    /* Hide app content while modals are open */
    body:has(.cdk-overlay-container:not(:empty)) big-root {
        @apply print:hidden;
    }

    .cdk-overlay-container {
        @apply z-modal;
        @apply print:relative;
    }

    .cdk-global-overlay-wrapper {
        @apply print:!justify-start print:!items-start print:!relative;
    }

    .ng-overlay-container {
        @apply !bg-white dark:!bg-dark-contrast !shadow-lg dark:!shadow-none !rounded-lg;
        @apply [&_#nav-user-content]:overflow-hidden [&_#nav-user-content]:max-h-[calc(100vh-275px)];
        @apply print:!shadow-none [&_#nav-user-content]:print:max-h-none print:relative;
    }

    .mat-sidenav {
        @apply !bg-gray-400 dark:!bg-dark-contrast !w-full md:!w-[480px];
    }

    .embedded-modal-panel {
        @apply md:!w-[916.667px] !w-[calc(100%-32px)] overflow-hidden !rounded-md;
    }

    .form-close-modal-panel {
        @apply !absolute top-32 md:!w-[730px] !w-[calc(100%-32px)] overflow-hidden !rounded-md;
    }

    .information-modal-panel {
        @apply !absolute top-1/2 -translate-y-1/2 md:!w-[730px] !w-[calc(100%-32px)] overflow-auto !rounded-md max-h-[70vh];
        @apply print:!w-full print:!relative print:translate-y-0;
    }

    .nav-modal-single-column {
        @apply min-w-[340px];
        @apply max-w-[400px];
    }

    .nav-modal-multi-column {
        @apply min-w-[616px];
        @apply max-w-[800px];
    }

    .mat-drawer-container {
        @apply min-h-screen;
    }

    .mat-mdc-optgroup-label {
        @apply font-bold text-primary;
    }

    .mat-mdc-option {
        @apply !min-h-[auto] !py-[6px];
    }

    .mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
        @apply !px-4;
    }

    /* stylelint-disable */
    .mat-mdc-option.mdc-list-item--disabled .mdc-list-item__primary-text {
        @apply !opacity-100;
    }

    .mat-mdc-option:hover,
    .mat-mdc-option-active:not(.mdc-list-item--disabled),
    .mat-mdc-optgroup .mat-mdc-option:hover:not(.mat-mdc-option-multiple) {
        @apply !bg-gray-400 !px-2 !mx-2 rounded-md text-primary-300;
    }

    /* stylelint-enable */

    /*
    Material Datepicker Styles
    To further decrease the amount of unused css,
    we write the classes normally applied through the theme ourself!
    */
    .cdk-visually-hidden {
        @apply sr-only;
    }

    .mat-date-range-input-separator,
    .mat-date-range-input-separator-hidden {
        @apply !opacity-100;
        @apply !text-gray-200;
    }

    .mat-ripple {
        @apply relative;
        @apply overflow-hidden;
    }

    .mat-ripple.mat-ripple-unbounded {
        @apply overflow-visible;
    }

    .mat-datepicker-popup {
        @apply !absolute;
        @apply !top-0;
        @apply !left-0;
    }

    .mat-calendar {
        @apply shadow-calender !h-auto;
    }

    .mat-calendar-content {
        @apply !p-6;
    }

    .mat-calendar-body-cell-content {
        @apply !rounded;
        @apply !border-2;
    }

    .mat-calendar-body-selected {
        @apply !bg-primary;
    }

    /* We can't hide this element, otherwise the datepicker will destroy itself :D */
    .mat-calendar-body .mat-calendar-body-label {
        @apply !pt-0;
        @apply !pb-0;
    }

    .mat-focus-indicator::before,
    .mat-mdc-focus-indicator::before {
        @apply absolute top-0 bottom-0 left-0 right-0 rounded-sm pointer-events-none;
    }

    .mat-focus-indicator:focus::before {
        @apply content-[''];
    }

    .cdk-high-contrast-active,
    .mat-mdc-focus-indicator:focus::before {
        --mat-focus-indicator-display: block;
    }

    .mat-mdc-focus-indicator {
        @apply relative;
    }

    .mat-calendar-controls {
        @apply !my-0;
    }

    .mat-date-range-seperator {
        @apply opacity-100;
    }

    .mat-calendar-controls,
    .mat-mdc-icon-button.mat-mdc-button-base,
    .mat-mdc-button-touch-target {
        @apply hidden;
    }

    .mat-mdc-icon-button.mat-mdc-button-base.mat-calendar-previous-button,
    .mat-mdc-icon-button.mat-mdc-button-base.mat-calendar-next-button {
        @apply block;
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
        @apply h-3;
        @apply w-3;
    }

    .mat-mdc-icon-button.mat-mdc-button-base.mat-calendar-previous-button::after,
    .mat-mdc-icon-button.mat-mdc-button-base.mat-calendar-next-button::after {
        @apply h-3;
        @apply w-3;
    }

    /*
    Material sets a width and height value of 40px here using this "--mdc-icon-button-state-layer-size" variable,
    applying those styles here breaks the calendar control box inside the calendar header.
    */
    .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
        @apply p-2;
    }

    .mat-theme-loaded-marker {
        @apply hidden;
    }

    .mat-app-background {
        @apply bg-zinc-50;
        @apply text-[rgba(0, 0, 0, 0.87)];
    }

    .mat-calendar-table-header-divider {
        @apply hidden;
    }

    .mat-calendar-table .mat-calendar-table-header th {
        @apply pb-0;
        @apply text-lg;
        @apply font-semibold;
    }

    mat-calendar-header .mat-calendar-header {
        @apply px-6;
        @apply pt-6;
    }

    button:not(.mat-calendar-body-in-range) .mat-calendar-body-cell-content {
        @apply hover:!bg-[#f6f6f8];
    }

    .mat-calendar-body-cell-container .mat-calendar-body-preview-end .mat-calendar-body-cell-content {
        @apply !bg-[#f6f6f8];
        @apply hover:!border-y-2;
        @apply hover:!border-l-0;
        @apply hover:border-[#ccdae7];
    }

    .mat-calendar-content .mat-calendar-body-in-preview .mat-calendar-body-cell-preview {
        @apply border-solid;
        @apply border-y-2;
    }

    .mat-calendar-previous-button,
    .mat-calendar-next-button {
        @apply !mb-4;
    }

    .mat-calendar-previous-button::after,
    .mat-calendar-next-button::after {
        @apply !border-primary;
        @apply rotate-45;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .mdc-button__label span {
        @apply text-lg;
        @apply font-semibold;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .mdc-button__label .mat-calendar-arrow {
        @apply m-0;
        @apply ml-2;
        @apply w-4;
        @apply h-4;
        @apply fill-primary;
    }

    .mat-elevation-z0,
    .mat-mdc-elevation-specific.mat-elevation-z0 {
        @apply shadow-[
            0px_0px_0px_0px_rgba(0, 0, 0, 0.2),
            0px_0px_0px_0px_rgba(0, 0, 0, 0.14),
            0px_0px_0px_0px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z1,
    .mat-mdc-elevation-specific.mat-elevation-z1 {
        @apply shadow-[
            0px_2px_1px_-1px_rgba(0, 0, 0, 0.2),
            0px_1px_1px_0px_rgba(0, 0, 0, 0.14),
            0px_1px_3px_0px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z2,
    .mat-mdc-elevation-specific.mat-elevation-z2 {
        @apply shadow-[
            0px_3px_1px_-2px_rgba(0, 0, 0, 0.2),
            0px_2px_2px_0px_rgba(0, 0, 0, 0.14),
            0px_1px_5px_0px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z3,
    .mat-mdc-elevation-specific.mat-elevation-z3 {
        @apply shadow-[
            0px_3px_3px_-2px_rgba(0, 0, 0, 0.2),
            0px_3px_4px_0px_rgba(0, 0, 0, 0.14),
            0px_1px_8px_0px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z4,
    .mat-mdc-elevation-specific.mat-elevation-z4 {
        @apply shadow-[
            0px_2px_4px_-1px_rgba(0, 0, 0, 0.2),
            0px_4px_5px_0px_rgba(0, 0, 0, 0.14),
            0px_1px_10px_0px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z5,
    .mat-mdc-elevation-specific.mat-elevation-z5 {
        @apply shadow-[
            0px_3px_5px_-1px_rgba(0, 0, 0, 0.2),
            0px_5px_8px_0px_rgba(0, 0, 0, 0.14),
            0px_1px_14px_0px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z6,
    .mat-mdc-elevation-specific.mat-elevation-z6 {
        @apply shadow-[
            0px_3px_5px_-1px_rgba(0, 0, 0, 0.2),
            0px_6px_10px_0px_rgba(0, 0, 0, 0.14),
            0px_1px_18px_0px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z7,
    .mat-mdc-elevation-specific.mat-elevation-z7 {
        @apply shadow-[
            0px_4px_5px_-2px_rgba(0, 0, 0, 0.2),
            0px_7px_10px_1px_rgba(0, 0, 0, 0.14),
            0px_2px_16px_1px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z8,
    .mat-mdc-elevation-specific.mat-elevation-z8 {
        @apply shadow-[
            0px_5px_5px_-3px_rgba(0, 0, 0, 0.2),
            0px_8px_10px_1px_rgba(0, 0, 0, 0.14),
            0px_3px_14px_2px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z9,
    .mat-mdc-elevation-specific.mat-elevation-z9 {
        @apply shadow-[
            0px_5px_6px_-3px_rgba(0, 0, 0, 0.2),
            0px_9px_12px_1px_rgba(0, 0, 0, 0.14),
            0px_3px_16px_2px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z10,
    .mat-mdc-elevation-specific.mat-elevation-z10 {
        @apply shadow-[
            0px_6px_6px_-3px_rgba(0, 0, 0, 0.2),
            0px_10px_14px_1px_rgba(0, 0, 0, 0.14),
            0px_4px_18px_3px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z11,
    .mat-mdc-elevation-specific.mat-elevation-z11 {
        @apply shadow-[
            0px_6px_7px_-4px_rgba(0, 0, 0, 0.2),
            0px_11px_15px_1px_rgba(0, 0, 0, 0.14),
            0px_4px_20px_3px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z12,
    .mat-mdc-elevation-specific.mat-elevation-z12 {
        @apply shadow-[
            0px_7px_8px_-4px_rgba(0, 0, 0, 0.2),
            0px_12px_17px_2px_rgba(0, 0, 0, 0.14),
            0px_5px_22px_4px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z13,
    .mat-mdc-elevation-specific.mat-elevation-z13 {
        @apply shadow-[
            0px_7px_8px_-4px_rgba(0, 0, 0, 0.2),
            0px_13px_19px_2px_rgba(0, 0, 0, 0.14),
            0px_5px_24px_4px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z14,
    .mat-mdc-elevation-specific.mat-elevation-z14 {
        @apply shadow-[
            0px_7px_9px_-4px_rgba(0, 0, 0, 0.2),
            0px_14px_21px_2px_rgba(0, 0, 0, 0.14),
            0px_5px_26px_4px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z15,
    .mat-mdc-elevation-specific.mat-elevation-z15 {
        @apply shadow-[
            0px_8px_9px_-5px_rgba(0, 0, 0, 0.2),
            0px_15px_22px_2px_rgba(0, 0, 0, 0.14),
            0px_6px_28px_5px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z16,
    .mat-mdc-elevation-specific.mat-elevation-z16 {
        @apply shadow-[
            0px_8px_10px_-5px_rgba(0, 0, 0, 0.2),
            0px_16px_24px_2px_rgba(0, 0, 0, 0.14),
            0px_6px_30px_5px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z17,
    .mat-mdc-elevation-specific.mat-elevation-z17 {
        @apply shadow-[
            0px_8px_11px_-5px_rgba(0, 0, 0, 0.2),
            0px_17px_26px_2px_rgba(0, 0, 0, 0.14),
            0px_6px_32px_5px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z18,
    .mat-mdc-elevation-specific.mat-elevation-z18 {
        @apply shadow-[
            0px_9px_11px_-5px_rgba(0, 0, 0, 0.2),
            0px_18px_28px_2px_rgba(0, 0, 0, 0.14),
            0px_7px_34px_6px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z19,
    .mat-mdc-elevation-specific.mat-elevation-z19 {
        @apply shadow-[
            0px_9px_12px_-6px_rgba(0, 0, 0, 0.2),
            0px_19px_29px_2px_rgba(0, 0, 0, 0.14),
            0px_7px_36px_6px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z20,
    .mat-mdc-elevation-specific.mat-elevation-z20 {
        @apply shadow-[
            0px_10px_13px_-6px_rgba(0, 0, 0, 0.2),
            0px_20px_31px_3px_rgba(0, 0, 0, 0.14),
            0px_8px_38px_7px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z21,
    .mat-mdc-elevation-specific.mat-elevation-z21 {
        @apply shadow-[
            0px_10px_13px_-6px_rgba(0, 0, 0, 0.2),
            0px_21px_33px_3px_rgba(0, 0, 0, 0.14),
            0px_8px_40px_7px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z22,
    .mat-mdc-elevation-specific.mat-elevation-z22 {
        @apply shadow-[
            0px_10px_14px_-6px_rgba(0, 0, 0, 0.2),
            0px_22px_35px_3px_rgba(0, 0, 0, 0.14),
            0px_8px_42px_7px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z23,
    .mat-mdc-elevation-specific.mat-elevation-z23 {
        @apply shadow-[
            0px_11px_14px_-7px_rgba(0, 0, 0, 0.2),
            0px_23px_36px_3px_rgba(0, 0, 0, 0.14),
            0px_9px_44px_8px_rgba(0, 0, 0, 0.12)
        ];
    }

    .mat-elevation-z24,
    .mat-mdc-elevation-specific.mat-elevation-z24 {
        @apply shadow-[
            0px_11px_15px_-7px_rgba(0, 0, 0, 0.2),
            0px_24px_38px_3px_rgba(0, 0, 0, 0.14),
            0px_9px_46px_8px_rgba(0, 0, 0, 0.12)
        ];
    }

    .cdk-high-contrast-active {
        --mat-mdc-focus-indicator-display: block;
    }

    .mat-primary {
        --mat-full-pseudo-checkbox-selected-icon-color: #3f51b5;
        --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3f51b5;
    }

    .mat-accent {
        --mat-option-selected-state-label-text-color: #ff4081;
    }

    .mat-warn {
        --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
        --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
        --mat-option-selected-state-label-text-color: #f44336;
    }

    .mat-datepicker-content.mat-accent {
        --mat-datepicker-calendar-date-selected-state-background-color: #ff4081;
        --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 64, 129, 0.4);
        --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 64, 129, 0.3);
        --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 64, 129, 0.3);
        --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 64, 129, 0.2);
    }

    .mat-datepicker-content.mat-warn {
        --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
        --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
        --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
        --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
        --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
    }

    .mat-datepicker-toggle-active.mat-accent {
        --mat-datepicker-toggle-active-state-icon-color: #ff4081;
    }

    .mat-datepicker-toggle-active.mat-warn {
        --mat-datepicker-toggle-active-state-icon-color: #f44336;
    }

    .mdc-line-ripple {
        display: none;
    }
}
